<template>
  <!-- 
    承認待ちステータス: 太文字付与
    承認否認ステータス: 打ち消し線
    交付済み以上ステータス: 透過 属性を付与する
  -->
  <div class="c-popup" ref="popup">
    <span v-if="reserve.proxyCollectMemberName != null || reserve.proxyDisposalMemberName != null"
    :class="[
        getStatusClass(reserve.generateStoreId),
        {
          needapproval: reserve.manifestStatusId === manifestStatus.RESERVE_PENDING,
          isdenied: reserve.manifestStatusId === manifestStatus.RESERVE_DENIAL,
          isapproved: reserve.manifestStatusId === manifestStatus.UNDER_RESERVE || reserve.manifestStatusId === manifestStatus.RESERVED || reserve.manifestStatusId === manifestStatus.UNDER_ISSUE,
          issued: reserve.manifestStatusId >= manifestStatus.ISSUED,
        },
      ]"
      class="c-reserve-proxy"
      >{{displayRegister()}}</span>
    <div
      class="c-reserve"
      :class="[
        getStatusClass(reserve.generateStoreId),
        {
          needapproval: reserve.manifestStatusId === manifestStatus.RESERVE_PENDING,
          isdenied: reserve.manifestStatusId === manifestStatus.RESERVE_DENIAL,
          isapproved: reserve.manifestStatusId === manifestStatus.UNDER_RESERVE || reserve.manifestStatusId === manifestStatus.RESERVED || reserve.manifestStatusId === manifestStatus.UNDER_ISSUE,
          issued: reserve.manifestStatusId >= manifestStatus.ISSUED,
        },
        displayIcon(reserve),
      ]"
      @click="clickHandler"
    >
      <div style="width: 93%">
        <div class="c-reserve__text">
          <span>{{ reserve.collectionTime }} {{ reserve.jwnetItemTypeName }}</span>
        </div>
        <template v-if="isReversePopup">
          <div class="c-reserve__text">
            <span>{{ reserve.generateStoreName }}</span>
          </div>
        </template>
        <div ref="popup_text" class="c-reserve__text">
          <span>{{ reserve.routeName }}</span>
        </div>
      </div>
    </div>

    <div class="c-popup__inner" ref="popup__inner">
      <span>{{ reserve.routeName }}</span>
    </div>
  </div>
</template>

<script>
import { DOMAIN } from "@/const";
import { showPopup } from "~/utils/popup"; 

export default {
  props: {
    reserve: {
      type: Object,
    },
    useStoreList: {
      type: Array,
    },
    isReversePopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      manifestStatus: DOMAIN.MANIFEST_STATUS,
      isCollector: this.$store.getters["userInfo/isCollector"],
    };
  },
  methods: {
    clickHandler() {
      this.$emit("showDetail");
    },
    getStatusClass(id) {
      let matchIndex;
      let colorCodeType = "";
      if (this.isCollector) {
        // 運搬事業者は全ての事業場を単一の色指定で表示する
        matchIndex = 3;
      } else {
        // 排出事業者は絞り込みされた事業場ごとに色を振り分ける
        this.useStoreList.map((item, index) => {
          if (item.id === id) {
            matchIndex = index;
          }
        });
      }

      switch (matchIndex) {
        case 0:
          colorCodeType = "red";
          break;
        case 1:
          colorCodeType = "green";
          break;
        case 2:
          colorCodeType = "skyBlue";
          break;
        case 3:
          colorCodeType = "orange";
          break;
        case 4:
          colorCodeType = "purple";
          break;
        default:
          break;
      }

      return colorCodeType;
    },
    displayIcon(reserve) {
      let showicon = "";
      
      let isProxyData = reserve.proxyCollectMemberName != null || reserve.proxyDisposalMemberName != null;
      let isRecurring = reserve.isRecurring;

      if (isProxyData && isRecurring) {
        // 繰り返し代理予約アイコン表示
        showicon = "recurproxydata";
      } else if (isProxyData) {
        // 代理予約アイコン表示
        showicon = "isproxyData";
      } else if (isRecurring) {
        // 繰り返しアイコン表示
        showicon = "recurring";
      }

      return showicon;
    },
    displayRegister(){
      let register = "";

      if (this.reserve.proxyCollectMemberName != null){
        register = "運代";
      } else if (this.reserve.proxyDisposalMemberName != null){
        register = "処代";
      }
      return register;
    },
  },
  mounted() {
    const popupRef = this.$refs.popup;
    const popupInnerRef = this.$refs.popup__inner;
    const popupTextRef = this.$refs.popup_text;
    showPopup(popupRef, popupInnerRef, popupTextRef);
  }
};
</script>
