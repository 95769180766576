<template>
  <div class="c-week">
    <div class="c-week__head">
      <div class="c-calendar__head">
        <div class="c-calendar__row">
          <div class="c-calendar__cell sunday">日</div>
          <div class="c-calendar__cell">月</div>
          <div class="c-calendar__cell">火</div>
          <div class="c-calendar__cell">水</div>
          <div class="c-calendar__cell">木</div>
          <div class="c-calendar__cell">金</div>
          <div class="c-calendar__cell saturday">土</div>
        </div>
      </div>
    </div>
    <div>
      <div class="c-calendar__body c-week__body">
        <!-- 時間部分 -->
        <div class="c-week__time">
          <template v-if="isPerHour">
            <div
              v-for="num of getHourPerHour()"
              :key="'time-' + num"
              class="c-week__cell"
            >
              {{ num }}
            </div>
          </template>
          <template v-else>
            <div
              v-for="num of getHourPerHalfMin()"
              :key="'time-' + num"
              class="c-week__cell"
            >
              {{ num }}
            </div>
          </template>
        </div>

        <div class="c-week__main">
          <div class="c-week__days">
            <div
              v-for="(day, dayIndex) in weekCalendars(currentDate)"
              :key="'day-' + dayIndex"
              class="c-week__days__each"
              :class="{
                today: isSameDate(day.full, today),
              }"
            >
              <!-- 日付部分 -->
              <div class="c-week__cell day">
                {{ day.date == 1 ? day.month + "/" + day.date : day.date }}
              </div>
              <!-- コンテンツ -->
              <div
                v-for="num of isPerHour
                  ? getHourPerHour()
                  : getHourPerHalfMin()"
                :key="'cell-' + num + '-' + dayIndex"
                class="c-week__cell"
              >
                <div v-if="reserves[day.fullStr]" class="c-week__cell__inner">
                  <!-- 終日 -->
                  <template v-if="num === '終日'">
                    <template
                      v-for="(reserve, reserveIndex) in getShowedReserves(
                        getAlldayReserves(reserves[day.fullStr])
                      )"
                    >
                      <ReserveItem
                        :key="'allday-' + reserveIndex"
                        :reserve="reserve"
                        :useStoreList="useStoreList"
                        v-if="
                          reserveIndex < 1 && reserve.collectionTime === null
                        "
                      />
                      <div
                        :key="reserveIndex"
                        v-if="reserveIndex == 1"
                        class="c-calendar__reserve other"
                      >
                        他{{
                          getShowedReserves(
                            getAlldayReserves(reserves[day.fullStr])
                          ).length - 1
                        }}件
                      </div>
                    </template>
                  </template>
                  <!-- 終日以外 -->
                  <template v-else>
                    <template
                      v-for="(reserve, reserveIndex) in getTimeMatch(
                        getShowedReserves(reserves[day.fullStr]),
                        day,
                        num
                      )"
                    >
                      <ReserveItem
                        :key="'day-' + reserveIndex"
                        :reserve="reserve"
                        :useStoreList="useStoreList"
                        v-if="
                          reserveIndex < 1 && reserve.collectionTime != null
                        "
                      />
                      <div
                        :key="reserveIndex"
                        v-if="
                          reserveIndex == 1 &&
                          reserve.collectionTime != null &&
                          isBetweenDate(
                            mergeFormatDateStr(day.fullStr, num),
                            formatReserveDate(reserve)
                          )
                        "
                        class="c-calendar__reserve other"
                      >
                        他{{
                          getTimeMatch(
                            getShowedReserves(reserves[day.fullStr]),
                            day,
                            num
                          ).length - 1
                        }}件
                      </div>
                    </template>
                  </template>
                </div>
                <!-- /.__inner -->

                <ReservePopup
                  :cellIndex="dayIndex"
                  :day="day"
                  :isPerHour="isPerHour"
                  :isMonth="false"
                >
                  <div
                    v-if="reserves[day.fullStr]"
                    class="c-calendar__popup__body"
                  >
                    <!-- 終日ポップアップ  -->
                    <template v-if="num === '終日'">
                      <template
                        v-for="(reserve, puReserveIndex) in getShowedReserves(
                          getAlldayReserves(reserves[day.fullStr])
                        )"
                      >
                        <ReserveItem
                          :key="'allday-pu-' + puReserveIndex"
                          :reserve="reserve"
                          :useStoreList="useStoreList"
                          isReversePopup=true
                          v-if="reserve.collectionTime === null"
                          @showDetail="reserveClick(reserve.manifestId)"
                        />
                      </template>
                    </template>

                    <!-- 終日以外ポップアップ  -->
                    <template v-else>
                      <template
                        v-for="(reserve, puReserveIndex) in getShowedReserves(
                          reserves[day.fullStr]
                        )"
                      >
                        <ReserveItem
                          :key="'day-pu-' + puReserveIndex"
                          :reserve="reserve"
                          :useStoreList="useStoreList"
                          isReversePopup=true
                          v-if="
                            reserve.collectionTime != null &&
                            isBetweenDate(
                              mergeFormatDateStr(day.fullStr, num),
                              formatReserveDate(reserve)
                            )
                          "
                          @showDetail="reserveClick(reserve.manifestId)"
                        />
                      </template>
                    </template>
                  </div>
                </ReservePopup>
              </div>
            </div>
          </div>
          <!-- /__days -->
        </div>
      </div>
      <!-- /.c-week -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ReserveItem from "./ReserveItem";
import ReservePopup from "./ReservePopup";
import generateCalendar from "../mixins/generateCalendar";
import timeManage from "../mixins/timeManage";
import sortFunction from "../mixins/sortFunction";

export default {
  components: {
    ReserveItem,
    ReservePopup,
  },
  mixins: [generateCalendar, timeManage, sortFunction],
  props: {
    reserves: {
      type: Object,
    },
    currentDate: {
      type: Object,
    },
    reserveClick: {
      type: Function,
    },
    isPerHour: {
      type: Boolean,
    },
    startHourPos: {
      type: Number,
    },
    useStoreList: {
      type: Array,
    },
    selectedStoreList: {
      type: Array,
    },
    selectedJwnetItemType: {
      type: Array,
    },
  },
  data() {
    return {
      today: moment(),
      startDate: "",
      endoDate: "",
    };
  },
  methods: {
    setWrapScrollPos() {
      const weekWrap = document.querySelector(".c-week");
      if (this.startHourPos === 0) {
        weekWrap.scrollTop = 0;
      } else {
        // セルの高さ
        if (this.isPerHour) {
          weekWrap.scrollTop = 50 * this.startHourPos;
        } else {
          weekWrap.scrollTop = 50 * this.startHourPos * 2;
        }
      }
    },

    // 時間内の予約を配列で返す
    getTimeMatch(reserves, day, time) {
      if (time === "終日") return;
      const newReserves = reserves.filter((reserve) => {
        if (
          this.isBetweenDate(
            this.mergeFormatDateStr(day.fullStr, time),
            this.formatReserveDate(reserve)
          )
        ) {
          return reserve;
        }
      });
      return newReserves;
    },

    getHourPerHour() {
      return Array.prototype.concat.apply(
        ["終日"],
        [...Array(24).keys()].map((v) => {
          return [String(v).padStart(2, "") + ":" + "00"];
        })
      );
    },

    getHourPerHalfMin() {
      return Array.prototype.concat.apply(
        ["終日"],
        [...Array(24).keys()].map((v) => {
          return [
            String(v).padStart(2, "") + ":" + "00",
            String(v).padStart(2, "") + ":" + "30",
          ];
        })
      );
    },

    getAlldayReserves(reserves) {
      return reserves.filter((reserve) => reserve.collectionTime === null);
    },
  },

  mounted() {
    this.setWrapScrollPos();
  },
};
</script>
